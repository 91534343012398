import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'summary',
    pathMatch: 'full'
  },
  {
    path: 'query-mag',
    loadChildren: () => import('./pages/query-mag/query-mag.module').then( m => m.QueryMagPageModule)
  },
  {
    path: 'summary',
    loadChildren: () => import('./pages/summary/summary.module').then( m => m.SummaryPageModule)
  },
  {
    path: 'clients',
    loadChildren: () => import('./pages/clients/clients.module').then( m => m.ClientsPageModule)
  },
  {
    path: 'client/:id',
    loadChildren: () => import('./pages/client/client.module').then( m => m.ClientPageModule)
  },
  {
    path: 'client',
    loadChildren: () => import('./pages/client/client.module').then( m => m.ClientPageModule)
  },
  {
    path: 'operators',
    loadChildren: () => import('./pages/operators/operators.module').then( m => m.OperatorsPageModule)
  },
  {
    path: 'operator/:id',
    loadChildren: () => import('./pages/operator/operator.module').then( m => m.OperatorPageModule)
  },
  {
    path: 'operator',
    loadChildren: () => import('./pages/operator/operator.module').then( m => m.OperatorPageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then( m => m.OrdersPageModule)
  },
  {
    path: 'orders/:page/:perPage',
    loadChildren: () => import('./pages/orders/orders.module').then( m => m.OrdersPageModule)
  },
  {
    path: 'planning',
    loadChildren: () => import('./pages/planning/planning.module').then( m => m.PlanningPageModule)
  },
  {
    path: 'planning/:documentoId',
    loadChildren: () => import('./pages/planning/planning.module').then( m => m.PlanningPageModule)
  },
  {
    path: 'machines',
    loadChildren: () => import('./pages/machines/machines.module').then( m => m.MachinesPageModule)
  },
  {
    path: 'flows',
    loadChildren: () => import('./pages/flows/flows.module').then( m => m.FlowsPageModule)
  },
  {
    path: 'flow',
    loadChildren: () => import('./pages/flow/flow.module').then( m => m.FlowPageModule)
  },
  {
    path: 'flow/:id',
    loadChildren: () => import('./pages/flow/flow.module').then( m => m.FlowPageModule)
  },
  {
    path: 'suppliers',
    loadChildren: () => import('./pages/suppliers/suppliers.module').then( m => m.SuppliersPageModule)
  },
  {
    path: 'items-meta/:page/:perPage/:categoriesIds/:group',
    loadChildren: () => import('./pages/items-meta/items-meta.module').then( m => m.ItemsMetaPageModule)
  },
  {
    path: 'items-meta',
    loadChildren: () => import('./pages/items-meta/items-meta.module').then( m => m.ItemsMetaPageModule)
  },
  {
    path: 'item-meta/:id',
    loadChildren: () => import('./pages/item-meta/item-meta.module').then( m => m.ItemMetaPageModule)
  },
  {
    path: 'item-meta',
    loadChildren: () => import('./pages/item-meta/item-meta.module').then( m => m.ItemMetaPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
