import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LabelsService {

  labels: any|null = null;

  constructor(private httpClient: HttpClient) {
    this.loadDataOnce();
  }

  public async loadDataOnce(): Promise<boolean> {
    return new Promise((resolve: CallableFunction) => {
      if (!!this.labels) { return resolve(true); }
      this.httpClient.get(`${environment.frontendBaseUrl}/assets/json/labels.json`)
        .subscribe({
          error: (error: any) => {
            console.log('loadLabelsOnce() failed -> ', error);
            return resolve(null);
          },
          next: (response: any) => {
            this.labels = response;
            return resolve(true);
          }
        });
    });
  }

  public async getLabelAsync(key: string) {
    if (!!this.labels === false) { await this.loadDataOnce(); }

    if (!!this.labels[key] === false || !!this.labels[key]['label'] === false) {
      return `${key} (no label set)`;
    }

    return this.labels[key]['label'];
  }

  public async getPlaceholderAsync(key: string) {
    if (!!this.labels === false) { await this.loadDataOnce(); }

    if (!!this.labels[key] === false || !!this.labels[key]['placeholder'] === false) {
      return `${key} (no placeholder set)`;
    }

    return this.labels[key]['placeholder'];
  }

  public getLabel(key: string): string {
    if (!!this.labels[key] === false || !!this.labels[key]['label'] === false) {
      return `${key} (no label set)`;
    }

    return this.labels[key]['label'];
  }

  public getPlaceholder(key: string): string {
    if (!!this.labels[key] === false || !!this.labels[key]['placeholder'] === false) {
      return `${key} (no placeholder set)`;
    }

    return this.labels[key]['placeholder'];
  }

}
