import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LabelsService } from './services/labels.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public env: any = environment;

  public appPages = [
    { title: 'Riepilogo', url: '/summary', icon: 'home', hideFromMenu: false },
    { title: 'Macchine', url: '/machines', icon: 'cog', hideFromMenu: false },
    { title: 'Operatori', url: '/operators', icon: 'people', hideFromMenu: false },
    { title: 'Flussi Produzione', url: '/flows', icon: 'git-merge', hideFromMenu: false },
    { title: 'Magazzino', url: '/items-meta', icon: 'pricetags', hideFromMenu: false },
    { title: 'Calendario', url: '/calendar', icon: 'calendar', hideFromMenu: false },
    { title: 'Ordini', url: '/orders', icon: 'cart', hideFromMenu: false },
    { title: 'Clienti', url: '/clients', icon: 'storefront', hideFromMenu: false },
    { title: 'Fornitori', url: '/suppliers', icon: 'contract', hideFromMenu: false },

    { title: 'MgrMag | Query', url: '/query-mag', icon: 'help', hideFromMenu: false },
  ];

  constructor(
    private readonly labelsService: LabelsService,
  ) {

    // Init labels to be ready in memory
    this.labelsService.loadDataOnce();
  }

}
