<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <ion-list id="main-menu">

          <ion-list-header class="ion-margin-bottom">
            <a  class="logo-link" [routerLink]="['/']">
              <ion-img class="logo-image" src="assets/imgs/logo-agspro.svg"></ion-img>
            </a>
          </ion-list-header>

          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item *ngIf="p.hideFromMenu === false" routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <!--
          <ng-container>
            <ion-list-header class="ion-margin-top">
              <ion-title>Debug/Development</ion-title>
            </ion-list-header>
            <ion-item lines="none" detail="false">
              <a slot="start" [href]="env.apiBaseUrl + '/dbseeds'" target="_blank" rel="noopener">
                DB Seeds
              </a>
            </ion-item>
          </ng-container>
          -->

        </ion-list>

      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
