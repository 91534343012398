export const environment = {
  production: true,

  /* PROD servers */
  apiBaseUrl: 'https://agspro.tboxlab.com:8000',
  frontendBaseUrl: 'https://agspro.tboxlab.com/app',
  adodbApiBaseUrl: null,

  // /* LOCAL DEV servers */
  // apiBaseUrl: 'https://localhost:8000',
  // frontendBaseUrl: 'http://localhost:8100',
  // adodbApiBaseUrl: 'https://localhost/adodb-api/api',

};
